(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/selfservice-integration/assets/javascripts/add-to-cart.js') >= 0) return;  svs.modules.push('/components/oddset/selfservice-integration/assets/javascripts/add-to-cart.js');
"use strict";


const logger = new svs.core.log.Logger('components:oddset:selfserviceIntegration');
const {
  CurrencyFromJupiterString
} = svs.utils.format;
const {
  generateProductItem,
  getCartInstance
} = svs.components.selfserviceCart;
const isSelfService = svs.utils.ns.isNamespace('svs.core.config.data.svsconfig.isSelfService') && svs.core.config.data.svsconfig.isSelfService;
const getBettingTypeDisplayName = name => {
  switch (name) {
    case 'combination':
      return 'Kombination';
    case 'singles':
      return 'Singel';
    case 'system':
      return 'System';
    default:
      return 'Oddset';
  }
};
const addToCart = sportsbookWagers => {
  if (!isSelfService) {
    throw new Error('Tried adding to cart while not in selfservice mode');
  }
  logger.debug('Adding to cart', sportsbookWagers);
  const products = [];
  sportsbookWagers.forEach(wager => {
    const productProperties = {
      wagers: JSON.stringify(wager),
      isLive: wager.includesLiveBet,
      headers: {
        source: 'SvS:LB:Sportsbook'
      },
      productFlag: 'oddset',
      cost: CurrencyFromJupiterString(wager.displayAmount),
      description: "Odds @ ".concat(wager.displayOdds),
      productTitle: getBettingTypeDisplayName(wager.bettingTypeName)
    };
    products.push(generateProductItem(productProperties));
  });
  getCartInstance().actions.addItems(products, () => {});
};
setGlobal('svs.components.oddset.selfserviceIntegration', {
  addToCart
});

 })(window);